<template>
  <div class="page">
    <div class="video-wrapper">
      <!-- muted="muted" -->
      <video
        id="myVideo"

        controls="controls"
        autoplay="autoplay"
      ></video>
    </div>
  </div>
</template>
      <script>
export default {
  props: {
    msg: String,
  },

  data() {
    return {
      videoStr:
        "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/%E6%82%A0%E6%82%A0%E8%90%B1%E8%8D%89%E9%A6%99%20%E6%9C%80%E5%BF%86%E9%BB%84%E8%8A%B1%E6%83%85%20%E6%97%A0%E5%AD%97%E5%B9%95.mp4",
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    init() {
      var video = document.getElementById("myVideo");
      var than = this;

    //   video.addEventListener("play", function () {
    //     //播放开始执行的函数
  
    //   });
      video.addEventListener("ended", function () {
        video.src = than.videoStr;
        video.play();
      });
  
      video.src = this.videoStr;
      video.play();
    },
  },
};
</script>
      
<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

video {
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>